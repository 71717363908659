import _react3 from "react";
import { dew as _generatePropsFromAttributes3Dew } from "../utils/generatePropsFromAttributes";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = StyleElementType;
  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _generatePropsFromAttributes = _generatePropsFromAttributes3Dew();

  var _generatePropsFromAttributes2 = _interopRequireDefault(_generatePropsFromAttributes);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
  /**
   * Converts a <style> element to a React element
   *
   * @param {Object} node The style node
   * @param {String} index The index of the React element relative to it's parent
   * @returns {React.Element} The React style element
   */


  function StyleElementType(node, index) {
    // The style element only ever has a single child which is the styles so try and find this to add as
    // a child to the style element that will be created
    var styles = void 0;

    if (node.children.length > 0) {
      styles = node.children[0].data;
    } // generate props


    var props = (0, _generatePropsFromAttributes2.default)(node.attribs, index); // create and return the element

    return _react2.default.createElement("style", props, styles);
  }

  return exports;
}