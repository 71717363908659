import { dew as _elementTypes3Dew } from "./elementTypes";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = convertNodeToElement;

  var _elementTypes = _elementTypes3Dew();

  var _elementTypes2 = _interopRequireDefault(_elementTypes);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
  /**
   * Converts a htmlparser2 node to a React element
   *
   * @param {Object} node The htmlparser2 node to convert
   * @param {Number} index The index of the current node
   * @param {Function} transform Transform function to apply to children of the node
   * @returns {React.Element}
   */


  function convertNodeToElement(node, index, transform) {
    return _elementTypes2.default[node.type](node, index, transform);
  }

  return exports;
}