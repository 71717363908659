var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = UnsupportedElementType;
  /**
   * Handles an unsupported element type by returning null so nothing is rendered
   * @returns {null}
   */

  function UnsupportedElementType() {
    // do nothing because the element type is unsupported
    // comment, directive, script, cdata, doctype are all currently unsupported
    return null;
  }

  return exports;
}