import { dew as _processNodes2Dew } from "./processNodes";
import { dew as _convertNodeToElement2Dew } from "./convertNodeToElement";
import _htmlparser2 from "htmlparser2";
import { dew as _HtmlParser3Dew } from "./HtmlParser";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.htmlparser2 = exports.convertNodeToElement = exports.processNodes = undefined;

  var _processNodes = _processNodes2Dew();

  Object.defineProperty(exports, "processNodes", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_processNodes).default;
    }
  });

  var _convertNodeToElement = _convertNodeToElement2Dew();

  Object.defineProperty(exports, "convertNodeToElement", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_convertNodeToElement).default;
    }
  });
  var _htmlparser = _htmlparser2;
  Object.defineProperty(exports, "htmlparser2", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_htmlparser).default;
    }
  });

  var _HtmlParser = _HtmlParser3Dew();

  var _HtmlParser2 = _interopRequireDefault(_HtmlParser);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = _HtmlParser2.default;
  return exports;
}