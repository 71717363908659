import { dew as _isEmptyTextNode3Dew } from "./utils/isEmptyTextNode";
import { dew as _convertNodeToElement3Dew } from "./convertNodeToElement";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = processNodes;

  var _isEmptyTextNode = _isEmptyTextNode3Dew();

  var _isEmptyTextNode2 = _interopRequireDefault(_isEmptyTextNode);

  var _convertNodeToElement = _convertNodeToElement3Dew();

  var _convertNodeToElement2 = _interopRequireDefault(_convertNodeToElement);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
  /**
   * Processes the nodes generated by htmlparser2 and convert them all into React elements
   *
   * @param {Object[]} nodes List of nodes to process
   * @param {Function} transform Transform function to optionally apply to nodes
   * @returns {React.Element[]} The list of processed React elements
   */


  function processNodes(nodes, transform) {
    return nodes.filter(function (node) {
      return !(0, _isEmptyTextNode2.default)(node);
    }).map(function (node, index) {
      // return the result of the transform function if applicable
      var transformed = void 0;

      if (typeof transform === "function") {
        transformed = transform(node, index);

        if (transformed === null || !!transformed) {
          return transformed;
        }
      } // otherwise convert the node as standard


      return (0, _convertNodeToElement2.default)(node, index, transform);
    });
  }

  return exports;
}